import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

const MyComponent = styled.div`
  ${tw`bg-blue-800
  rounded-lg
  overflow-hidden
  border`}
  height: 409px;
  img {
    height: 265px;
    object-fit: cover;
    object-position: center;
  }
`

export default MyComponent